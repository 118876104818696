<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="선택해주세요" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>UniLive 소프트웨어 라이센스 및 서비스 계약 </h2>



		<p>

			머리말 <br />

			UniLive 소프트웨어 라이센스 및 서비스 계약(이하 "본 계약")은 PIX TECHNOLOGY PTE입니다.

			LTD사의 UniLive 소프트웨어(이하 "UniLive" 또는 "우리")와 귀하(이하 "사용자") 간에 귀하가 본 소프트웨어를 다운로드, 설치, 로그인, 사용하고 UniLive 관련 서비스를 사용하는 데
			체결한 계약입니다.본 계약 내용을 자세히 읽어 주십시오.본 계약의 내용 또는 페이지 프롬프트에 대한 질문이 있을 경우 다음 단계를 수행하지 마십시오.UniLive 플랫폼의 공식 고객센터를 통해 문의하시면
			저희가 설명해 드리고 설명해 드릴 수 있습니다.귀하가 페이지를 클릭하거나 UniLive 소프트웨어와 관련된 서비스를 직접 시작하는 등 다른 방식으로 확인하면 귀하가 본 협의에 동의했음을
			나타냅니다.<br />

		</p>

		<p>

			만약에 귀하가 만 18세 미만의 미성년자이거나 지적, 정신건강 상태로 인해 완전한 민사행위 능력을 가지지 못할 경우 귀하는 보호자의 지도와 동반하에 본 협의와 기타 관련 협의를 읽고 미성년자가 관련 조항을
			사용하는 것을 주의하며 보호자의 동의를 얻어 UniLive 소프트웨어와 관련 서비스를 사용하십시오.특히 보호자의 명시적 동의 없이 유니라이브가 제공하는 충전소비 관련 서비스를 이용하지 말고 보호자의
			보호책무를 성실히 이행해 주시기 바랍니다.

		</p>

		<h3> 본 계약은 다음을 이해하는 데 도움이 됩니다</h3>

		<p> 1. 정의</p> 

		<p>2. 협의의 적용 범위 및 개정 </p>

		<p> 3. UniLive 소프트웨어 라이센스 및 사용 사양</p>

		<p> 4, 계정의 등록, 사용 및 로그아웃 </p>

		<p> 5. 사용자 개인정보보호</p>

		<p>6, 미성년자 이용약관</p>

		<p> 7. 사용자 행동 규범 </p>

		<p> 8. 데이터 사용 규범 </p>

		<p>9. 법에 따른 납세 의무</p>

		<p>10, 지적재산권</p>

		<p> 11, 홍보 </p>

		<p>12、단일 서비스에 관한 특수 약정</p>

		<p> 13, 서비스 변경, 중단 및 종료 </p>

		<p> 14. 면책 성명 </p>

		<p>15, 위약처리 </p>

		<p>16, 기타 </p>

		<h3> 1. 정의</h3>

		<p>

			1. UniLive 플랫폼은 UniLiveAPP 및 이후에 개통될 수 있는 다른 사이트, 웹페이지, 클라이언트, 애플릿과 기술 발전에 따라 나타난 새로운 서비스 매개체를 말한다.

		</p>

		<p>

			2. 본 서비스/서비스란 UniLive 플랫폼을 통해 사용자에게 제공하는 관련 서비스(정보 저장 공간을 제공하여 사용자에게 인터넷 음성, 동영상, 인터넷 생방송, 검색 등을 제공하거나 이를 위해 제공하는
			기타 관련 서비스를 포함하되 이에 국한되지 않음)를 말한다.

		</p>

		<p>

			3. UniLive 사용자/사용자, 등록 사용자 및 미등록 사용자 포함.등록 사용자는 UniLive 플랫폼의 일부 기능과 서비스를 사용하기 위해 UniLive 계정을 만든 사용자입니다.당사의 제품 및/또는
			서비스를 등록하지 않은 사용자는 당사의 제품 및/또는 서비스를 사용하기 시작할 때부터 비등록 사용자가 되며, 본 계약에서 등록된 사용자의 고유한 약정을 제외한 모든 약관을 준수해야 합니다.

		</p>

		<p>

			4. UniLive 계정/계정은 사용자가 UniLive 플랫폼에 등록하고 사용하는 계정을 가리키며 귀하가 계정을 등록한 후 UniLive 플랫폼을 통해 생방송/짧은 동영상 촬영, 게시, 시청, 댓글,
			공유, 검색 등 기능을 포함하되 이에 국한되지 않는 기능 중 하나 이상을 실현할 수 있습니다.

		</p>

		<p>

			5.UniLive 플랫폼 규칙, 모든 UniLive가 이미 게시했거나 앞으로 게시, 개정할 수 있는 각종 서비스 약관, 규칙, 규범, 규칙 해독, 실시 세칙, 통지, 공고 등 규칙성 문건을 포함하며,
			구체적으로 관련 페이지 전시를 기준으로 한다.특히 UniLive 플랫폼의 특정 제품 및/또는 서비스를 사용할 때 관련 서비스 약관 또는 규칙에 유의해야 합니다.귀하가 본 계약에 동의하거나 본 서비스를
			실제로 사용하는 경우 귀하는 상술한 조항에 동의하는 것으로 간주됩니다.

		</p>



		<h3> 2. 협의의 적용 범위 및 개정 </h3>

		<p>

			1. 협의의 적용 주체 범위, 당신은 UniLive 플랫폼은 종합적인 서비스 플랫폼으로 귀하가 사용하는 해당 서비스가 체결한 협의나 해당 서비스에 공시된 정보에 근거하여 귀하와 계약을 이행하는
			UniLive 플랫폼 운영자 주체를 확정할 수 있음을 알고 계십시오.

			본 계약에 따라 UniLive 플랫폼 운영자는 UniLive 플랫폼의 업무 조정에 따라 변경될 수 있으며, 변경된 UniLive 플랫폼 운영자는 귀하와 함께 본 계약을 이행하고 귀하에게 서비스를
			제공합니다.UniLive 플랫폼 운영자는 UniLive 플랫폼이 새로운 서비스를 제공하기 때문에 추가될 수도 있습니다. 예를 들어 귀하가 새로운 서비스를 사용하는 경우 귀하가 새로운 서비스에 대응하는
			UniLive 플랫폼 운영자가 귀하와 함께 본 협의를 이행하는 것에 동의하는 것으로 간주됩니다.UniLive 플랫폼 내의 서비스 사용으로 인해 분쟁이 발생할 경우 분쟁의 상대자는 사용자가 구체적으로
			사용하는 서비스에 해당하는 UniLive 플랫폼 운영자입니다.

		</p>

		<p>

			2. 협의의 범위,

			본 계약은 UniLive 플랫폼 규칙을 포함하며 본 계약의 불가분의 일부분으로 본 계약과 동등한 법적 효력을 가진다.

			또한 귀하가 당사 플랫폼에서 제3자가 제공한 제품 및/또는 서비스를 사용하는 경우 본 계약의 약관을 준수하는 것 외에 제3자의 서비스 약관도 준수해야 합니다.

		</p>

		<p>

			3. 협의의 개정,

			UniLive는 법률, 법규, 국가정책, 기술조건, 제품기능 등 변화수요에 따라 적시에 본 협의를 개정할수 있으며 UniLive는 개정된 협의를 발포한다.앞에서 서술한 내용이 정식으로 발표되면
			UniLive는 적당한 방식으로 사용자 (탄창제시, 웹사이트공포, 시스템통지 등, 구체적으로 실제기준) 에게 송달된다.개정된 계약 내용 또는 페이지 안내 정보에 대해 궁금한 사항이 있는 경우 UniLive
			플랫폼의 공식 고객센터를 통해 문의하여 설명 및 설명할 수 있습니다.귀하가 페이지를 클릭하거나 다른 방법(예를 들어 계속 사용)을 통해 확인한 것은 귀하가 수정된 협의에 동의했음을 나타냅니다.

		</p>



		<h3> 3. UniLive 소프트웨어 라이센스 및 사용 사양</h3>

		<p>1. 소프트웨어 라이센스의 범위</p>

		<p>

			1.1

			이 서비스를 사용하려면 UniLive 소프트웨어를 다운로드해야 할 수도 있습니다. UniLive는 해당 기술 및 UniLive 기술에 대해 개인, 취소 가능, 양도 불가능 및 비배타적 라이센스만
			부여합니다.UniLive 소프트웨어는 본 서비스를 사용할 목적으로만 다운로드, 설치, 사용, 실행할 수 있습니다.

		</p>

		<p>

			1.2

			본 조항 및 본 계약의 기타 조항에 명시되지 않은 다른 모든 권한은 UniLive에 의해 유지되며 귀하는 이러한 권리를 행사할 때 UniLive의 서면 허가를 받아야 합니다.UniLive가 보유권을
			행사하지 않으면 보유권 포기가 되지 않습니다.

		</p>

		<p>

			2. 소프트웨어 획득

		</p>

		<p>

			2.1 UniLive 소프트웨어와 관련 서비스를 사용하여 UniLive 응용 프로그램을 사전 설치, UniLive가 승인된 제3자(예: App Store) 다운로드 등을 통해 얻을 수 있습니다.

		</p>

		<p>

			2.2 UniLive가 아니거나 UniLive가 허가되지 않은 제3자로부터 UniLive 소프트웨어를 받은 경우 UniLive는 소프트웨어의 정상적인 사용을 보장할 수 없으며 이로 인해 발생하는 손실에
			대해 책임을 지지 않습니다.

		</p>

		<p>

			3. 소프트웨어 설치 및 제거

		</p>

		<p>

			3.1 UniLive는 서로 다른 터미널, 시스템 등에 대해 서로 다른 소프트웨어 버전을 개발할 수 있으므로 실제 상황에 따라 적합한 버전을 다운로드하여 설치하십시오.

		</p>

		<p>

			3.2 설치 프로그램을 다운로드한 후 이 프로그램이 제시하는 절차에 따라 올바르게 설치하십시오.

		</p>

		<p>

			3.3 소프트웨어를 더 이상 사용하지 않거나 새 버전의 소프트웨어를 설치해야 하는 경우 직접 제거할 수 있습니다.UniLive를 통해 제품 서비스를 개선하려는 경우 설치 제거 이유를 알려줄 수 있기를
			기대합니다.

		</p>

		<p>

			4. 소프트웨어 업데이트

		</p>

		<p>

			4.1 UniLive는 사용자 경험을 개선하고 서비스 내용을 보완하기 위해 새로운 서비스를 개발하고 사용자에게 소프트웨어 업데이트를 제공할 것입니다. (이러한 업데이트는 소프트웨어 교체, 수정, 기능
			강화, 버전 업그레이드 등 형식을 취할 수 있습니다.)

		</p>

		<p>

			4.2 UniLive는 본 소프트웨어와 서비스의 보안 및 기능의 일관성을 보장하기 위해 사용자에게 특별한 통지 없이 소프트웨어를 업데이트하거나 소프트웨어의 일부 기능 효과를 변경하거나 제한할 수 있습니다.

		</p>

		<p>

			4.3 이 소프트웨어의 새 버전이 릴리즈되면 이전 버전의 소프트웨어에서 일부 기능을 사용할 수 없습니다.UniLive는 이전 버전의 소프트웨어를 계속 사용할 수 있고 적절한 고객 서비스를 제공할 수 없음을
			보장하지 않으므로 최신 버전을 즉시 다운로드하십시오.

		</p>




		<h3> 4, 계정의 등록, 사용 및 로그아웃 </h3>

		<p>

			1. 등록

			UniLive 계정을 만들어 플랫폼의 일부 기능과 서비스를 사용할 수 있습니다.저희 플랫폼에서 UniLive 계정의 등록/로그인을 직접 하실 수 있습니다.



		</p>

		<p>

			2. 사용

		</p>

		<p>

			2.1 사용자는 UniLive 계정 정보 및 계정 비밀번호의 안전을 잘 보관할 책임이 있으며 사용자는 UniLive 계정과 비밀번호 아래의 행위에 대해 법적 책임을 져야 한다.사용자는 어떠한 상황에서도
			다른 사람에게 계정 및 비밀번호 정보를 누설하지 않는 것에 동의하며, UniLive도 자발적으로 당신에게 계정 비밀번호를 요구하지 않는다는 것을 알고 주십시오.계정이 도난당하는 등 안전하지 않은 것으로
			의심되는 경우 UniLive에 즉시 알려 주십시오.계정 보안과 관련하여 UniLive는 현재 기술 수준에서 서버의 계정 보안을 보호하고 기술 조치를 지속적으로 업데이트하기 위해 노력할 것입니다.귀하가
			자발적으로 누설하거나 타인의 공격, 사기 등 행위로 인해 초래된 손실과 결과는 사법, 행정 등 구제 경로를 통해 권리 침해 행위자에게 배상을 청구할 수 있습니다.UniLive의 지원이 필요한 경우
			UniLive는 해당 법률의 테두리 안에서 최선을 다해 지원합니다.

		</p>

		<p>

			2.2 UniLive 계정의 소유권은 베이징UniLive 과학기술유한공사에 귀속됩니다. 사용자가 등록 신청 수속을 마친 후에 등록된 UniLive 계정은 본인만 사용할 수 있습니다.또한 귀하의 계정
			행위(각종 협의, 정보 발표, 상품 및 서비스 구매 및 정보 공개 등을 포함하되 이에 국한되지 않음)는 모두 귀하의 행위를 대표하기 때문에 귀하는 귀하의 계정 정보와 비밀번호를 잘 보관하고 귀하의 계정
			행위의 결과에 대해 책임을 져야 합니다. UniLive의 서면 동의 없이 귀하는 어떠한 형식의 대여, 임대, 증여, 양도, 판매 또는 다른 방식으로 타인의 UniLive 계정 사용을 허가할 수
			없습니다.만약에 귀하의 계정이 본인에게 속하지 않는 조작이나 사용을 당하면 UniLive에 즉시 통지하여 확대된 손실과 결과를 피하십시오.UniLive가 귀하의 UniLive 계정의 사용이 귀하의 계정
			보안 및/또는 UniLive 소프트웨어 정보 보안을 위태롭게 할 수 있다고 판단하는 경우, 귀하는 UniLive가 귀하 본인이 신뢰할 수 있는 방식으로 복구를 요구할 때까지 해당 계정이 UniLive
			소프트웨어 정보 보안에 대한 위협이 제거될 때까지 해당 서비스 제공을 중단할 수 있다는 데 동의합니다.

		</p>

		<p>

			2.3 귀하는 귀하가 설정한 UniLive 계정 정보가 국가 법률, 법규, 정책 및 UniLive가 제공하는 서비스에 관한 규칙을 위반해서는 안 된다는 것을 이해하고 약속합니다.사용자가 설정한 계정 이름,
			UniLive 번호, 프로필 사진, 개인 소개 및 개인 정보 등 등록 정보에 위법 또는 불량 정보가 나타나지 않아야 하며, 타인의 허가 없이 타인의 명의(타인의 이름, 명칭, 상표, 상호, 초상, 프로필
			등 독특한 식별적 요소를 가지고 있거나 헷갈릴 수 있는 방식을 포함하되 이에 국한되지 않음)를 사용하여 UniLive 계정을 등록하거나 UniLive 계정을 설정해서는 안 되며, 빈번한 등록, 대량 등록
			등 행위를 통해 악의적으로 계정을 등록해서는 안 된다.

		</p>

		<p>

			2.4 국가 관련 법률, 법규의 규정과 감독 정책의 요구에 따라 대중이 공공의 이익을 위해 감독을 실시하고 좋은 지역사회 질서를 조성하기 위해 UniLive는 귀하의 계정 개인정보 페이지와 같은 위치에
			귀하가 사용하는 계정이 합리적인 범위 내의 인터넷 프로토콜(IP) 주소 귀속지 등 정보를 전시하고 구체적인 전시 위치, 형식, 정보 유형 등 전시 관련 안배는 관련 법률, 법규의 규정과 실제 전시 상황을
			기준으로 합니다. 이에 귀하는 이를 충분히 알고 동의합니다.

		</p>

		<p>

			2.5 계정의 안전을 보호하고 계정이 도난당하는 등 상황을 방지하기 위해 저희는 한 가지 또는 여러 가지 방식으로 등록된 사용자에게 사용자 신분 검증을 할 수 있습니다(예를 들어 메일 검증 등). 만약에
			사용자가 인증에 성공하지 못하면 저희는 이 계정이 도난당하는 등 안전하지 않은 상황이 발생한 것을 의심할 합리적인 이유가 있고 심각한 상황에 따라 이 계정에 저희 제품 및/또는 서비스를 계속 제공할지
			여부를 결정하거나 진일보한 조치를 취할 수 있습니다.귀하는 우리가 상술한 조치를 취한 것은 사용자 계정의 안전을 보호하기 위한 것이며, 우리는 이로 인해 불합리한 책임을 질 필요가 없다는 것에 동의합니다.

		</p>
		<p>

			2.6 사용자가 UniLive 계정을 등록한 후 두 달 연속 이 계정에 로그인하지 않으면 UniLive는 계정을 동결, 회수, 교체, 말소할 권리가 있으며, 동시에 UniLive 소프트웨어 데이터베이스에
			있는 이 계정의 모든 기록 (등록 정보, 가상 자산 등을 포함하되 이에 국한되지 않음) 을 삭제, 비우는 등 정리 조치를 취하여 자원 낭비를 초래하지 않을 권리가 있으며, 이로 인한 어떠한 손실도 사용자가
			스스로 부담한다.

		</p>

		<p>

			2.7 귀하의 계정이 동결 조치를 받은 경우 UniLive 플랫폼에서 제공하는 다른 인증 방식을 통해 귀하의 계정을 다시 활성화할 수 있습니다.

		</p>

		<p>

			3. 로그아웃

			저희는 귀하께 UniLive 계정 로그아웃 기능을 제공합니다. 귀하는 UniLive 플랫폼에서 제공하는 온라인 로그아웃 방식, 저희 고객센터에 연락하거나 저희가 제공한 다른 방식으로 귀하의 계정을
			로그아웃할 수 있습니다.



		</p>



		<h3> 5. 사용자 개인정보보호</h3>



		<p>

			1. 저희는 개인정보가 귀하에게 얼마나 중요한지 잘 알고 있기 때문에 저희는 귀하의 프라이버시와 개인정보를 보호하는 것을 매우 중시하고 귀하의 개인정보를 매우 신중한 의무로 취급하고 처리합니다.귀하가 저희
			제품 및/또는 서비스를 사용하는 과정에서 저희는 관련 기술적 조치와 기타 안전 조치를 취해 귀하의 개인 정보를 보호할 것입니다.

		</p>

		<p>

			2. 귀하가 계정을 등록하거나 관련 서비스를 사용하는 과정에서 저희는 귀하가 협조하여 필요한 정보를 제공해야 합니다. 예를 들어 귀하의 메일박스 등 귀하가 관련 서비스에 필요한 개인 정보에 대한 권한
			수여에 동의하지 않으면 해당 서비스를 사용할 수 없거나 사용 과정에서 제한을 받습니다.

		</p>

		<p>

			3.우리는 귀하의 개인정보에 대한 관리를 매우 중시하고 개인정보에 대한 귀하의 열람, 복제, 정정, 보충, 삭제, 동의 수권 철회 및 계정 말소, 고소 신고 등 권리를 최선을 다해 보호하여 귀하가 귀하의
			개인정보 안전을 이해하고 보장할 수 있도록 합니다.

		</p>

		<p>

			4.우리는 미성년자의 개인 정보에 대한 보호를 매우 중시합니다.만 18세 미만의 미성년자인 경우 UniLive의 서비스를 이용하기 전에 부모 또는 다른 보호자의 사전 동의를 얻어야 합니다.

		</p>

		<p>

			5. UniLive가 귀하의 개인 정보를 수집, 사용, 저장 및 보호하는 방법과 귀하가 누리는 권리에 대해 UniLive 개인 정보 보호 정책에 액세스하여 자세히 알아보십시오.

		</p>



		<h3> 6. 미성년자 이용약관</h3>

		<p>

			1. 귀하가 만 18세 미만의 미성년자인 경우 귀하는 보호자의 보호, 지도 및 보호자의 동의를 얻어 본 협의를 읽고 UniLive 소프트웨어와 관련 서비스를 사용해야 합니다.

		</p>

		<p>

			2. UniLive는 미성년자의 개인정보에 대한 보호를 중시한다. 미성년자 사용자는 개인정보를 작성할 때 개인 보호 의식을 강화하고 신중하게 대해야 한다. 보호자의 지도 아래 UniLive 소프트웨어와
			관련 서비스를 정확하게 사용해야 한다.

		</p>

		<p>

			3.미성년자가 본 소프트웨어와 관련 서비스를 사용하려면 보호자의 감독 지도 아래 합리적인 범위 내에서 인터넷 사용을 정확하게 학습하고 가상의 인터넷 공간에 빠지지 않도록 하며 좋은 인터넷 습관을 길러야
			한다. 인터넷 학습을 잘하고 불량한 정보를 조회하지 않아야 한다.성실하고 우호적으로 교류하며 타인을 모욕하고 사기치지 말아야 한다;자호의식을 강화하고 마음대로 네티즌을 데이트하지 말아야 한다.네트워크
			보안을 유지하고 네트워크 질서를 파괴하지 않아야 한다;심신 건강에 유익해야지, 가상 시공간에 빠지지 말아야 한다.

		</p>

		<p>

			4. 귀하의 피보호자는 UniLive에서 제공하는 서비스를 사용할 때 충전, 타상 등 기능을 사용할 수 있습니다.귀하는 보호자로서 귀하의 지불계좌를 잘 보관하여 피보호자가 귀하의 동의를 얻지 않고 귀하의
			지불계좌를 통해 충전, 포상 기능을 사용하지 않도록 하십시오.UniLive는 이러한 행위를 방지하기 위해 여러분과 함께 노력하고자 합니다.

		</p>

		<p>

			5.미성년자의 프라이버시 권익을 더욱 잘 보호하기 위하여 UniLive는 귀하에게 미성년자의 영상이 포함된 내용을 신중하게 발포할 것을 주의를 환기시킵니다. 발포하자마자 귀하가 이미 권리자의 동의를 얻어
			미성년자의 초상, 소리 등 정보를 전시한 것으로 간주하고 UniLive가 본 협의에 따라 미성년자와 관련된 내용을 사용하고 관리할 수 있도록 허용합니다.권리자가 UniLive에 게시한 콘텐츠가 미성년자의
			권리를 침해한다고 통지하거나 미성년자의 권리를 보호하기 위한 기타 고려에 따라 UniLive는 게시한 콘텐츠를 처리하고 통지할 권리가 있습니다.

		</p>



		<h3> 7. 사용자 행동 규범 </h3>

		<p>

			1. 사용자 행위 요구, 귀하는 귀하가 UniLive 소프트웨어와 관련 서비스를 사용하는 행위에 대해 책임을 져야 합니다. 법률이 허용하거나 UniLive의 사전 서면 허가를 받지 않는 한 귀하가
			UniLive 소프트웨어와 관련 서비스를 사용하는 행위는 다음과 같은 행위를 할 수 없습니다.



		</p>

		<p>

			1.1 UniLive 라이센스 또는 라이센스가 없는 플러그인, 플러그인, 외장형, 시스템 또는 타사 도구를 사용하여 UniLive 소프트웨어 및 관련 서비스의 정상적인 작동을 방해, 손상, 수정 또는 기타
			영향을 미칩니다.

		</p>

		<p>

			1.2 UniLive 소프트웨어 및 관련 서비스를 이용하거나 다음과 같은 컴퓨터 네트워크 보안을 해치는 모든 행위

		</p>

		<p>

			1.2.1 타인의 네트워크에 불법으로 침입하고 타인의 네트워크 정상적인 기능을 방해하며 네트워크 데이터를 훔치는 등 네트워크 안전을 해치는 활동;

			1.2.2 네트워크 침입, 네트워크 정상 기능 및 보호 조치 방해, 네트워크 데이터 절취 등 네트워크 안전을 해치는 활동에 전문적으로 사용되는 프로그램, 도구를 제공한다.

			1.2.3 타인이 사이버 안전을 해치는 활동에 종사하는 것을 알면서도 기술 지원, 광고 홍보, 지불 결제 등 도움을 제공한다.

			1.2.4 허가받지 않은 데이터를 사용하거나 허가받지 않은 서버/계정에 접속한다.

			1.2.5 허락 없이 공중컴퓨터 네트워크나 타인의 컴퓨터 시스템에 들어가서 저장된 정보를 삭제, 수정, 추가한다.

			1.2.6 허가 없이 UniLive 시스템이나 네트워크의 취약점을 탐지, 스캔, 테스트하거나 네트워크 보안을 파괴하는 기타 행위를 시도하는 행위

			1.2.7 UniLive 시스템이나 사이트의 정상적인 운행에 간섭, 파괴를 시도하고 악성 프로그램이나 바이러스를 고의로 전파하며 정상적인 인터넷 정보 서비스를 방해하는 다른 행위를 한다.

			1.2.8 TCP/IP 패킷 이름 또는 일부 이름을 위조합니다.



		</p>

		<p>

			1.3 UniLive 소프트웨어에 대한 리버스 엔지니어링, 리버스 어셈블리, 컴파일 또는 다른 방식으로 소프트웨어의 소스 코드를 검색합니다.

		</p>

		<p>

			1.4 UniLive 소프트웨어 또는 UniLive 소프트웨어가 실행되는 동안 터미널 메모리에 저장된 데이터, 소프트웨어가 실행되는 동안 클라이언트와 서버의 상호 작용 데이터, UniLive 소프트웨어가
			실행되는 데 필요한 시스템 데이터, 플러그인, 플러그인, 플러그인, 그룹 제어 또는 UniLive에 의해 승인되지 않은 타사 도구/서비스를 사용하여 UniLive 관련 시스템과 시스템에 액세스하는 것을
			포함하되 이에 국한되지 않는 파생된 작업을 UniLive 소프트웨어 또는 작성합니다.

		</p>

		<p>

			1.5 UniLive 소프트웨어가 실행 중인 명령어, 데이터를 수정하거나 위조하여 UniLive 소프트웨어의 기능이나 실행 효과를 증가, 삭제, 변동시키거나 상기 용도로 사용할 소프트웨어, 방법을
			운영하거나 대중에게 전파한다. 이러한 행위가 상업적 목적이든 아니든 상관없다.

		</p>

		<p>

			1.6 UniLive 소프트웨어(및 해당 복사본)와 관련 콘텐츠(예: 짧은 비디오)에서 지적 재산권에 대한 정보를 삭제하거나 지적 재산권 보호를 위해 설정된 기술적 조치를 수정, 삭제, 회피합니다.

		</p>

		<p>

			1.7 UniLive가 지적재산권을 가진 콘텐츠 또는 UniLive 플랫폼 내 다른 사용자가 게시한 콘텐츠에 대한 사용, 대여, 대여, 복제, 수정, 링크, 전재, 편집, 발표, 출판, 수직 검색, 미러링
			사이트 구축 등이다.

		</p>

		<p>

			1.8 UniLive 계정을 이용하여 범죄 방법 전수, 불법 약물 판매, 돈세탁, 사기 등 불법적이거나 불법적일 가능성이 있는 모든 활동이나 거래에 참여한다.

		</p>

		<p>

			1.9 법률, 법규, 본 협의, UniLive 플랫폼 규칙을 위반하고 타인의 합법적 권익을 침해하는 기타 행위.

			UniLive가 귀하의 행위가 상술한 약정을 위반하거나 위반할 수 있다고 판단할 합리적인 이유가 있는 경우, UniLive는 독립적으로 판단하고 필요한 조치를 취하여 처리할 수 있으며, 긴급한 경우 사전
			통지 없이 귀하에게 서비스를 종료하고 법에 따라 관련 책임을 추궁할 수 있습니다.

		</p>

		<p>

			2. 정보 내용 규범

		</p>

		<p>

			2.1 본 조항에 기술된 정보 내용은 사용자가 본 소프트웨어와 서비스를 사용하는 과정에서 작성, 복제, 발표, 전파된 모든 내용을 말한다. 이는 UniLive 계정의 프로필 사진, 닉네임, 개인 소개 등
			개인 홈페이지에 전시된 정보나 게시, 전파된 문자, 사진, 음성, 영상, 생방송 등 정보와 기타 UniLive 계정이나 본 소프트웨어와 서비스를 사용하여 발생한 내용을 포함하되 이에 국한되지 않는다.

		</p>

		<p>

			2.2 귀하는 UniLive가 사용자에게 문명적이고 건전하며 규범적이고 질서정연한 네트워크 환경을 제공하기 위해 노력해 왔다는 것을 이해하고 동의합니다. 귀하는 UniLive 계정이나 본 소프트웨어와
			서비스를 이용하여 UniLive의 정상적인 운영을 방해하고 다른 사용자나 제3자의 합법적인 권익을 침해하는 내용을 제작, 복제, 발표, 전파해서는 안 됩니다.

		</p>
		<p>2.3 UniLive 동영상 업로드 기본 규칙</p>
		<p>
			콘텐츠 가이드라인(커뮤니티 규범)
		</p>
		<p>
			금지된 폭력적 콘텐츠: 폭력, 상해, 학대 또는 테러 관련 콘텐츠(실제 또는 모방 포함)를 업로드하는 것은 금지됩니다.
		</p>
		<p>
			금지된 음란 및 노골적인 콘텐츠: 노출, 성적 암시, 음란 또는 모든 형태의 성인 콘텐츠는 금지됩니다.
		</p>

		<p>
			금지된 혐오 발언: 인종, 종교, 성별, 성적 지향, 국적 등을 기반으로 특정 집단을 차별하거나 공격하거나 증오를 조장하는 콘텐츠의 게시를 금지합니다.
		</p>
		<p>
			금지된 괴롭힘 및 희롱: 타인을 모욕, 협박, 또는 괴롭히는 콘텐츠(미성년자를 대상으로 한 부적절한 행동 포함)를 업로드하는 것은 금지됩니다.
		</p>
		<p>
			금지된 위험 행위: 부상으로 이어질 수 있는 위험한 챌린지, 장난, 또는 불법 행위(예: 마약 사용, 무기 취급)를 보여주는 동영상의 업로드는 금지됩니다.
		</p>

		<p>

			3. 활동 참여 규범

		</p>

		<p>

			UniLive 또는 UniLive 플랫폼의 제3자는 비정기적으로 각종 활동을 전개한다. 활동에 참여하기 전에 귀하는 해당 활동 페이지에 들어가서 활동 규칙을 자세히 읽고 충분히 이해해야 한다. 귀하가
			활동에 참여하는 것은 귀하가 이 활동 규칙을 충분히 읽고 이해한 것으로 간주하고 자발적으로 활동 규칙의 제약을 받아야 한다.활동 규칙을 읽는 동안 해당 내용을 이해하지 못하거나 동의하지 않을 경우 해당
			활동의 참여를 중지하십시오.

			위험 관리 시스템은 부당한 수단을 사용하여 활동에 참여한 사용자 (본 계약 또는 활동 규칙의 금지 규정을 위반한 활동 참여자) 를 자동으로 선별하고 제거한다는 것을 알고 주십시오.귀하는 UniLive가
			자체 플랫폼의 빅데이터 분석 능력, 기술 식별 능력에 근거하여 관련 행위의 위반 여부를 독립적으로 판단할 권리가 있으며, 부정한 수단을 이용하여 활동에 참여한 사용자에 대하여 활동 참여 자격, 평가 자격
			및 수상 자격을 취소하고, 부정한 수단을 이용하여 이익을 얻은 결과에 대하여 인정하지 않으며, 동시에 이미 지급한 장려금을 회수할 권리가 있다는 것에 동의합니다.

		</p>

		<p>

			4. 자신의 행동에 책임을 진다

		</p>

		<p>

			귀하는 귀하가 자신이 등록한 계정의 행위에 대해 책임을 져야 한다는 것을 이해하고 동의합니다. 귀하가 발표한 모든 내용, 논평, 좋아요, 추천, 검색 등 행위와 이로 인해 발생하는 모든 결과를
			포함합니다.귀하는 본 서비스의 내용을 자체적으로 판단하고 내용의 합법성, 정확성, 완전성 또는 실용성에 대한 의존으로 인해 발생하는 모든 위험을 부담해야 합니다.UniLive는 앞서 설명한 위험으로 인한
			손실이나 손해에 대해 책임을 지지 않으며 책임을 지지 않습니다.

		</p>

		<p>

			UniLive는 귀하와 타인의 지적재산권, 명예권, 성명권, 프라이버시와 같은 합법적인 권익을 존중하고 보호합니다.귀하는 UniLive 소프트웨어와 관련 서비스를 사용할 때 올린 문자, 사진, 음성,
			동영상, 생방송, 링크 등이 제3자의 지적재산권, 명예권, 성명권, 프라이버시 등 권리와 합법적인 권익을 침해하지 않을 것을 보증합니다.그렇지 않으면 UniLive는 권리자 또는 관련자의 통지를 받은 경우
			해당 침해 혐의를 제거할 권리가 있습니다.제3자가 제기한 모든 권리 주장에 대해 귀하는 이로 인해 발생할 수 있는 모든 법적 책임을 스스로 처리하고 부담해야 합니다.귀하의 권리 침해 행위로 인해
			UniLive 또는 해당 당사자가 손해를 입은 경우(경제, 영업권 등 손실을 포함하되 이에 국한되지 않음), 귀하는 UniLive 또는 해당 당사자가 입은 모든 손실을 전액 배상해야 합니다.

		</p>



		<h3> 8. 데이터 사용 규범</h3>

		<p>

			1. UniLive의 서면 허가 없이 사용자는 UniLive 소프트웨어 및 관련 서비스의 정보 내용에 대해 제3자가 다음과 같은 행위를 하도록 스스로 또는 권한을 부여, 허용, 협조할 수 없습니다.

		</p>

		<p>

			1.1 UniLive 소프트웨어 및 관련 서비스의 정보 내용을 복제, 읽기, 채택하여 홍보, 독서량, 조회수 증가 등 상업적 용도를 포함하되 이에 국한되지 않는다.

		</p>

		<p>

			1.2 유니라이브 소프트웨어 및 관련 서비스의 정보 내용을 임의로 편집, 정리, 편성한 후 유니라이브 소프트웨어 및 관련 서비스의 소스 페이지 이외의 채널에서 전시한다.

		</p>

		<p>

			1.3 특수 표지, 특수 코드 등 어떠한 형식의 식별 방법을 포함하되 이에 국한되지 않는 방법으로 제3자가 UniLive 소프트웨어와 관련 서비스의 정보나 내용에 대해 데이터, 조회수 안내, 이전, 납치
			등 부적절한 행위를 하거나 협조한다.

		</p>

		<p>

			1.4 기타 UniLive 소프트웨어 및 관련 서비스의 정보 내용을 불법으로 취득하는 행위

		</p>

		<p>

			2. UniLive의 서면 허가를 받은 후 사용자가 UniLive 소프트웨어 및 관련 서비스에 대한 정보와 내용의 공유, 전달 등 행위는 다음과 같은 규범에 부합해야 한다.

		</p>

		<p>

			2.1 캡처, 통계, 얻은 관련 검색어, 적중률, 분류, 검색량, 조회수, 조회수 등 관련 데이터에 대해 UniLive의 사전 서면 동의 없이 상술한 데이터를 어떠한 방식으로든 공시, 제공, 누설해서는 안
			된다.

		</p>

		<p>

			2.2 UniLive 소프트웨어 및 관련 서비스의 원본 웹 페이지에 대해 어떠한 형식의 변경도 할 수 없다. UniLive 소프트웨어 및 관련 서비스의 첫 페이지(profile) 링크, 광고 시스템 링크
			등 입구를 포함하되 이에 국한되지 않으며 UniLive 소프트웨어 및 관련 서비스의 원본 페이지 전시에 대해 어떠한 형식의 차단, 삽입, 탄창 등 방해도 할 수 없다.

		</p>

		<p>

			2.3 UniLive 소프트웨어 및 관련 서비스의 정보 내용이 제3자에 의해'스파이더'(spider) 프로그램을 포함하되 이에 국한되지 않는 어떠한 형식으로도 불법으로 획득되는 것을 방지하기 위해 안전하고
			효과적이며 엄밀한 조치를 취해야 한다.

		</p>

		<p>

			2.4 관련 데이터 내용을 UniLive의 서면 허가 범위 이외의 목적으로 어떠한 형태의 판매와 상업적 사용을 하거나 제3자에게 어떠한 방식의 사용도 누설, 제공 또는 허용해서는 안 된다.

		</p>

		<p>

			2.5 사용자가 제3자에게 UniLive 소프트웨어 및 관련 서비스 정보의 내용을 공유, 전달, 복제하는 행위는 UniLive가 이를 위해 제정한 기타 규범과 기준도 준수해야 한다.

		</p>



		<h3> 9. 법에 의한 납세의무 </h3>

		<p>

			UniLive 플랫폼에서 발생한 수익이나 획득한 보상 등 관련 수익(이하 "관련 수익")은 본인의 지불 계좌로 현금으로 인출할 수 있습니다.국가 관련 법률, 행정 법규 및 관련 세수 규범성 문건의 규정에
			따라 귀하가 UniLive 플랫폼에서 발생한 관련 수익은 법에 따라 관련 세금을 납부하거나 납세 신고를 해야 합니다.귀하는 UniLive 플랫폼이 법에 따라 세무기관의 요구에 따라 귀하를 위해 세무기관에
			세금을 원천징수하거나 납세신고를 대리할 때 귀하의 개인정보와 납세자료 (실명, 신분증 정보 및 연락처 등에 국한되지 않음) 를 수집하거나 사용해야 하며 귀하는 UniLive 플랫폼과 협력하여 납세의무를
			적극적으로 이행해야 한다는 것을 이해하고 동의합니다.

		</p>



		<h3> 10. 지적재산권</h3>

		<p>

			1. UniLive가 본 소프트웨어 및 관련 서비스에서 제공하는 콘텐츠(소프트웨어, 기술, 프로그램, 웹페이지, 텍스트, 사진, 이미지, 오디오, 비디오, 차트, 레이아웃 디자인, 전자 문서 등을 포함하되
			이에 국한되지 않음)의 지적재산권은 UniLive의 소유이다.UniLive가 이 서비스를 제공할 때 의존하는 소프트웨어의 저작권, 상표권, 특허권, 영업비밀 및 기타 지적재산권은 모두 UniLive의
			소유이다.UniLive의 허가 없이 누구도 임의로 사용하고 양도할 수 없다(로봇, 스파이더와 같은 프로그램이나 장치를 통해 본 소프트웨어와 관련 서비스의 내용을 감시, 복제, 전파, 전시, 미러링,
			업로드, 다운로드하는 것을 포함하되 이에 국한되지 않는다).

		</p>

		<p>

			2. 귀하는 본 소프트웨어와 관련 서비스를 사용할 때 게시하고 업로드한 텍스트, 사진, 동영상, 오디오 등은 귀하가 창작하거나 합법적인 권한을 부여받았음을 보증합니다(전체 권한 포함). 귀하가
			UniLive 소프트웨어를 통해 업로드하고 게시한 콘텐츠의 지적재산권은 귀하 또는 원시 저작권자에게 귀하가 소유합니다.

		</p>

		<p>

			3. 귀하의 작품을 더욱 잘 공유하고 홍보하기 위해 귀하와 귀하의 작품의 영향력을 확대합니다. 귀하가 UniLive와 상반된 서면 약정을 체결하지 않는 한, 귀하는 UniLive 소프트웨어와 관련 서비스를
			사용할 때 발표/업로드한 내용(문자, 그림, 동영상, 오디오 등 성과를 포함하되 이에 국한되지 않으며 앞에서 서술한 성과에 포함된 인물의 초상권, 공연자권, 음악, 소리, 그리고 그 범위에 제한되지 않는
			비독점적 권리 및 리벤토리 및 리벤토리를 포함하되 리벤토리 범위에 포함, 리벤토리 및 리벤토리에 제한되지 않는 비독점적 권리 및 리벤토리 및 리벤토리 및 리벤토리 및 리벤토리 및 리벤토리 관련 요소에
			포함, 리벤토리 및 기타 기타 기타 요소에 포함되지 않는 권한을 부여하는 비독점적 권리 및 리벤토리 권리, 편집권, 공연권, 정보망 전파권, 방송권, 파생상품 제작 등),앞서 설명한 라이선스 사용의
			범위에는 UniLive 플랫폼 또는 UniLive와 협력하는 기타 타사 플랫폼, 응용 프로그램, 제품 또는 단말기, 웹 페이지, 페이지 등 기존 및 미래에 나타날 담체가 포함되지만 이에 국한되지는
			않는다.귀하는 UniLive가 홍보나 제품/기능 업그레이드, 신제품/기능 연구의 목적으로 상술한 내용(전체 또는 부분)을 사용하거나 개발할 수 있도록 제3자가 허가할 수 있다는 데 동의하며,
			UniLive가 상술한 내용의 사용이나 개발에 대해 인신권 또는 보수를 주장하지 않을 것을 약속합니다.일부 서비스 기능의 특성을 기반으로 UniLive 소프트웨어를 통해 업로드한 콘텐츠는 다른
			UniLive 사용자가 UniLive 소프트웨어를 사용하여 콘텐츠를 제작하고 게시할 때 사용할 수 있으며, 사용자가 앞서 설명한 내용에 따라 재창작한 콘텐츠는 UniLive 플랫폼 또는 UniLive와
			제휴한 제3자 플랫폼에서만 전파할 수 있으며 UniLive의 동의 없이 어떠한 상업적 용도로도 사용할 수 없습니다.UniLive에서 승인된 콘텐츠의 전부 또는 일부를 계속 사용하지 않으려면 UniLive에
			공지된 대로 UniLive에 승인 취소를 알릴 수 있습니다.

		</p>

		<p>

			4. 귀하의 작품 노출량과 발표 효율을 높이기 위해 귀하는 귀하가 UniLive 소프트웨어와 관련 서비스를 사용할 때 발표한 모든 내용을 UniLive에 권한을 부여하여 귀하의 계정으로
			북경UniLive과학기술유한공사 또는 그 관련 회사가 운영하는 다른 클라이언트, 사이트, 웹 페이지 및 애플릿 등 서비스 매개체에 자동으로 동시 발표하는 것에 동의합니다. 귀하의 계정의 프로필 사진,
			닉네임 등 공개 비공개 정보는 동시에 동기화될 수 있습니다.UniLive에 게시된 콘텐츠를 자동으로 동기화할 수 있는 권한을 계속 부여하지 않으려면 UniLive 소프트웨어 관련 작업 페이지의 프롬프트를
			통해 스스로 권한을 취소하거나 다른 공시를 통해 UniLive에 권한 취소를 알릴 수 있습니다.

		</p>

		<p>

			5. 귀하는 UniLive가 UniLive 자신의 명의로 또는 제3자에게 귀하가 업로드하고 발표한 지적재산권을 침해하거나 합법적으로 권한을 부여받은 내용에 대해 대리 권리를 옹호하는 것을 확인하고
			동의합니다. 이때 귀하는 UniLive의 요구에 따라 해당하는 서면 증명 서류를 제출해야 합니다.권익옹호 형식에는 권리침해 행위 모니터링, 권익옹호 서한 발송, 소송 또는 중재, 조정, 화해 등이
			포함되지만 이에 국한되지 않으며, UniLive는 권익옹호 사항에 대해 결정을 내리고 독립적으로 실시할 권리가 있다.

		</p>

		<p>

			6. 귀하가 본 소프트웨어와 관련 사이트의 메모리가 귀하의 권리를 침해하는 내용을 발견하면 즉시 이메일을 통해 【unilive.team@gmail.com"UniLive에 통지하고 귀하의 권리에 대한 초기
			증거를 제공하며 UniLive는 법률 규정에 따라 귀하의 불만을 적시에 처리합니다.

		</p>

		<p>

			7.UniLive는 본 소프트웨어 및 관련 서비스의 개발, 운영에 대한 기술 지원을 제공하고 본 소프트웨어 및 관련 서비스의 개발과 운영 등 과정에서 발생하는 모든 데이터와 정보 등에 대해 모든 권리를
			가진다.

		</p>

		<p>

			8. UniLive의 "UniLive 테크놀로지" "UniLive" "unilive.com" 등을 포함한 상표, 서비스 태그, 상호, 도메인 이름, 사이트 이름 또는 기타 주요 브랜드 특징 등을 어떠한
			경우에도 개인적으로 사용하지 마십시오 (이하 "표시").UniLive의 사전 서면 동의 없이 귀하는 본 약관에 명시된 표지를 단독 또는 결합 등 어떠한 방식으로도 전시, 사용하거나 해당 표지를 처리하는
			다른 행위를 실시할 수 없습니다.귀하가 본 계약을 위반하여 UniLive 회사의 상술한 상표, 로고 등을 사용하여 UniLive 또는 타인에게 손해를 입힌 경우 귀하는 모든 법적 책임을 집니다.

		</p>



		<h3> 11, 보급 </h3>

		<p>

			1.귀하는 UniLive 소프트웨어와 관련 서비스를 제공하는 과정에서 당사가 자체적으로 또는 당사 파트너가 다양한 방식으로 상업성 광고(광고 링크 포함) 또는 기타 모든 유형의 상업성 및 비상업성
			정보(이상 통칭하여 "홍보 정보")를 배포하거나 마운트할 권리를 보유한다는 것을 이해하고 동의합니다.이 권리에는 UniLive가 귀하가 업로드, 배포할 수 있는 콘텐츠 및 계정과 같은 UniLive
			플랫폼의 어느 곳에서나 홍보 정보를 배포 및/또는 마운트하는 것이 포함되지만 이에 국한되지는 않습니다.법률과 법규를 준수하는 전제하에 이와 같은 보급정보의 투입 및/또는 마운트의 형식, 시간, 위치,
			내용은 그 어떤 제3자의 간섭도 받지 않는다.

		</p>

		<p>

			2. 상업성 광고에 관하여

		</p>

		<p>

			2.1 만약에 귀하가 관련성이 있는 광고를 받지 않으려고 한다면 귀하는 해당 광고 정보에 대해 "관심 없음" 을 선택할 권리가 있습니다. 그러면 해당 광고와 같은 광고의 푸시가 줄어들 것입니다.

		</p>

		<p>

			2.2 저희는 법률의 규정에 따라 광고 관련 의무를 이행하지만 귀하는 광고 합작자와 광고 내용은 저희가 통제하지 않고 귀하는 광고 내용에 대해 그 진실성과 신뢰성을 신중하게 판단해야 한다는 것을 이해하고
			동의합니다.귀하가 저희 제품 및/또는 서비스를 통해 광고 합작자와 어떠한 형식의 행위를 하거나 어떠한 형식의 분쟁이 발생하는 경우 귀하가 책임지고 해결하며 법률과 법규에 규정된 저희의 책임을 제외하고
			저희는 어떠한 추가 책임도 지지 않지만 필요에 따라 법에 따라 필요한 협조를 제공할 것입니다.

		</p>

		<p>

			2.3 UniLive 푸시 알림 서비스를 받지 않으려는 경우 귀하는 핸드폰 시스템 알림 관리에서 이 서비스를 스스로 종료할 권리가 있습니다.

		</p>



		<h3> 12, 단일 서비스에 관한 특별 약정</h3>

		<p>

			1. UniLive 소프트웨어 및 관련 서비스에는 UniLive가 다양한 합법적인 방식으로 획득한 정보 또는 정보 내용 링크가 포함되어 있으며, UniLive와 그 관련 회사가 합법적으로 운영하는 기타
			단일 서비스도 포함되어 있으며, UniLive는 이러한 특별 분야의 설정 및 서비스를 수시로 증가, 감소 또는 변경할 수 있다.UniLive 소프트웨어에서 위의 개별 서비스 기능을 켜고 사용할 수
			있습니다.일부 개별 서비스는 귀하가 이 서비스에 대해 특별히 제정한 협의나 귀하와 이 서비스 제공자 간의 규칙을 동시에 수락해야 할 수도 있습니다.UniLive는 이러한 프로토콜, 규칙을 명확하게
			제공합니다.귀하가 협의에 동의하거나 상기 서비스를 사용하기 시작하면 귀하는 단일 서비스와 관련된 협의, 규칙의 약정 내용을 동시에 받아들인 것으로 간주됩니다.

		</p>

		<p>

			2. 귀하가 UniLive 소프트웨어에서 제3자가 제공한 소프트웨어 및/또는 관련 서비스를 사용할 때 본 협의와 UniLive 플랫폼 규칙을 준수하는 것 외에 제3자의 협의, 관련 규칙도 준수해야
			합니다.타사 소프트웨어 및/또는 관련 서비스로 인해 발생하는 분쟁, 손실 또는 손해는 귀하가 타사와 직접 해결하는 경우 UniLive는 귀하 또는 타사에 대한 책임을 지지 않습니다.

		</p>



		<h3> 13, 서비스 변경, 중단 및 종료 </h3>

		<p>

			1. 법률과 법규가 허용하는 범위 내에서 저희는 저희의 업무 발전 상황에 따라 일시적 또는 영구적으로 저희 제품 및/또는 서비스(또는 그 일부)를 변경하거나 중지할 수 있습니다. 사전에 합리적인 기한 내에
			사이트 내 편지, 갱신/중지 공고 등 서면으로 알린 후에 해당 변경 또는 중지는 귀하와 그 어떠한 제3자에게 위약 책임을 지지 않습니다.

		</p>

		<p>

			2. 상술한 경우를 제외하고 다음과 같은 상황이 발생할 경우 저희는 귀하께 제공하는 제품 및/또는 서비스를 중지하거나 중지할 권리가 있습니다.

		</p>

		<p>

			2.1 귀하가 자발적으로 요청한 경우

		</p>

		<p>

			2.2 귀하가 존재하거나 우리가 독립적으로 판단한 후에 귀하가 국가 법률, 법규나 감독 정책을 위반하거나 본 협의를 위반하거나 우리의 명예, 권익을 훼손하는 행위를 했다고 판단한 경우.

		</p>

		<p>

			2.3 법률, 법규, 감독 정책의 규정 또는 권한 있는 기관의 요구에 따른다.

		</p>

		<p>

			2.4 계정과 시스템 안전 등 긴급 상황을 유지하기 위한 필요;

		</p>

		<p>

			2.5 불가항력(불가항력이란 예견할 수 없고 피할 수 없으며 극복할 수 없는 객관적인 상황을 말한다. 인터넷의 특수성을 감안하여 본 협의에서 말하는 불가항력은 해킹, 전신부서의 기술조정으로 인한 중대한
			영향, 정부의 통제로 인한 일시적인 폐쇄, 바이러스 침습 등 인터넷의 정상적인 운행에 영향을 주는 상황도 포함한다).

		</p>

		<p>

			2.6 다른 거부할 수 없는 상황.

		</p>

		<p>

			3. 상술한 종료 상황이 발생하면 귀하와 저희는 다음과 같은 처리 방식을 인정합니다.

		</p>

		<p>

			3.1 만약에 귀하가 저희 플랫폼에서 진행 중인 거래가 있다면 저희는 상황에 따라 합리적으로 처리할 것입니다.

		</p>

		<p>

			3.2 만약에 귀하가 본 협의를 위반하여 종료될 경우 저희는 상황에 따라 귀하에게 상응하는 위약 책임을 요구할 권리가 있습니다.

		</p>

		<p>

			3.3 법률과 규정에 규정이 있거나 저희가 따로 설명하는 경우를 제외하고 저희는 귀하와 제3자에게 어떠한 책임도 지지 않습니다.

		</p>



		<h3> 14. 면책 성명 </h3>

		<p>

			1. 귀하는 UniLive 소프트웨어와 관련 서비스가 여러 가지 요소에 의해 영향을 받거나 방해될 수 있음을 이해하고 동의합니다. 법률과 규정에 명시된 경우를 제외하고 UniLive는 보증하지
			않습니다(포함하되 이에 국한되지 않음):

		</p>

		<p>

			1.1 UniLive 소프트웨어 및 관련 서비스는 사용자의 사용 수요에 적합하다.

		</p>

		<p>

			1.2 UniLive는 간섭을 받지 않고 신속하고 안전하며 신뢰할 수 있으며 오류가 발생하지 않습니다.및 사용자가 UniLive를 통해 취득한 소프트웨어, 서비스 또는 기타 자료는 사용자의 기대에
			부합한다.

		</p>

		<p>

			1.3 UniLive 소프트웨어의 모든 오류는 수정될 수 있습니다.

		</p>

		<p>

			2. 대출 또는 기타 재산과 관련된 인터넷 정보, 계좌 비밀번호, 광고 또는 홍보 등 정보가 의심되는 경우, 당신은 신중하게 대하고 스스로 판단하십시오. 그렇지 않으면 귀하가 이로 인해 입은 이윤, 상업
			신용, 자료 손실 또는 기타 유형 또는 무형의 손실, UniLive는 어떠한 직접, 간접, 부수, 특별, 파생성 또는 징벌성의 법적 책임을 지지 않습니다.

		</p>
		<p>
			3.귀하는 UniLive 소프트웨어와 관련 서비스를 사용하는 과정에서 불가항력과 같은 요소가 발생할 수 있음을 이해하고 동의합니다.불가항력이 발생할 경우 UniLive는 가장 빠른 시간내에 제때에 복구하기
			위해 노력하지만 불가항력으로 인해 사용자에게 손실을 초래하였기에 사용자는 UniLive가 책임을 지지 않는다는데 동의하였다.

		</p>

		<p>

			4. UniLive는 본 협의의 약정에 따라 법규위반내용을 처리할 권리를 얻는데 이 권리는 UniLive의 의무나 승낙을 구성하지 않으며 UniLive는 제때에 위법행위를 발견하거나 상응한 처리를 보장할수
			없다.

		</p>

		<p>

			5. 귀하는 UniLive가 상업적 적합성, 특정 목적의 적합성 등을 포함하되 이에 국한되지 않는 본 서비스에 관한 어떠한 종류의 명시적 또는 묵시적 보증이나 조건도 제공하지 않는다는 것을 이해하고
			동의합니다.

		</p>

		<p>

			6.귀하는 본 협의가 쌍방이 국가 법률과 법규를 준수하고 공서양속을 수호하며 타인의 합법적 권익을 보호하고 UniLive 사용자의 사용 체험을 향상시키는 원칙에 근거하여 초안을 작성하고 체결한다는 것을
			이해하고 동의합니다.합의 이행 과정에서 UniLive가 심사, 감독관리 또는 기타 플랫폼의 의무를 이행해야 하는 사항에 대해 UniLive는 능력 범위 내에서 관련 법률, 법규에 따라 관련 업무를 집행,
			판단하기 위해 최선을 다할 것이지만, UniLive 판단이 사법기관, 행정기관의 판단과 완전히 일치한다는 보장은 없으며, 이로 인해 발생하는 결과를 사용자가 이미 이해하고 스스로 부담하는 것에 동의한다.

		</p>



		<h3>15, 위약처리 </h3>

		<p>

			1.UniLive는 귀하가 본 협의 또는 기타 UniLive 플랫폼 규칙을 위반하는 행위에 대해 독립적으로 판단하고 상황에 따라 경고 알림, 기한부 시정, 계정 일부 또는 전체 기능 제한, 서비스 제공
			종료, 계정 폐쇄, 재등록 금지 등 조치를 취할 권리가 있습니다. 이로 인해 귀하가 계정 및 관련 서비스를 정상적으로 사용할 수 없고 귀하의 계정 내 자산 또는 기타 권익을 정상적으로 획득할 수 없는 등
			결과나 손실(사용자 자료, 가상 자산 또는 손실 등을 포함하되 이에 국한되지는 않음), 귀하가 책임지지 않거나 관련 제품을 비울 수 없는 경우 Live, 또는 UniLive의 책임을 지지
			않습니다.UniLive는 위반 사건과 그 처리 결과를 공지할 권리가 있으며, 실제 상황에 따라 관련 계정의 사용 재개 여부를 결정할 권리가 있다.유니라이브는 법률, 법규 위반 혐의, 위법 범죄 혐의가 있는
			행위에 대하여 관련 기록을 보존하고, 법에 따라 관련 주관부서에 보고, 관련 주관부서의 조사에 협조하고, 공안기관에 신고할 권리가 있으며, 이미 삭제된 내용인 유니라이브에 대해서는 회복하지 않을 권리가
			있다.

		</p>

		<p>

			2. 귀하가 본 협의나 기타 UniLive 플랫폼 규칙의 규정을 위반하여 제3자의 고소나 소송 청구를 일으킨 경우 귀하는 이로 인해 발생할 수 있는 모든 법적 책임을 스스로 처리하고 부담해야 합니다.귀하의
			위법 또는 위약과 같은 행위로 인해 UniLive 또는 관련 방향의 제3자가 배상하거나 국가기관의 처벌을 받은 경우, 귀하는 UniLive 또는 그 관련 측이 이로 인해 입은 모든 손실을 전액 배상해야
			합니다.

		</p>




		<h3> 16, 기타 </h3>

		<p>

			1. 본 협의의 모든 조항의 제목은 읽기가 편리하고 그 자체는 실제적인 의미가 없으며 본 협의의 의미 해석의 근거로 삼을 수 없다.

		</p>

		<p>

			2. 본 협의 조항은 어떠한 이유로 부분적으로 무효하거나 집행할 수 없는 경우에도 나머지 조항은 유효하고 쌍방에 대해 구속력을 가진다.

		</p>
	</div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          value: "中文",
          label: "中文",
        },
        {
          value: "English",
          label: "English",
        },
        {
          value: "한국인",
          label: "한국인",
        },
        {
          value: "日本語",
          label: "日本語",
        }, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
      ],
      value: "",
      language: "",
    };
  },
  created() {
    // url获取参数
    this.language = this.$route.query.language;
    if (this.language == "zh_CN") {
      this.$router.push({
        path: "/UserServiceAgreementZh",
      });
    }
    if (this.language == "en_US") {
      this.$router.push({
        path: "/UserServiceAgreementEn",
      });
    }
    if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/UserServiceAgreementKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/UserServiceAgreementJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/UserServiceAgreementRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/UserServiceAgreementVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/UserServiceAgreementTh'
			})
		}
  },
  methods: {
    clickChange(value) {
      this.value = value;
      if (value == "中文") {
        this.$router.push({
          path: "/UserServiceAgreementZh",
        });
      }
      if (value == "English") {
        this.$router.push({
          path: "/UserServiceAgreementEn",
        });
      }
      if (value == "한국인") {
        this.$router.push({
          path: "/UserServiceAgreementKo",
        });
      }
      if (value == "日本語") {
        this.$router.push({
          path: "/UserServiceAgreementJa",
        });
      }
      if (value == 'Русский') {
				this.$router.push({
					path: '/UserServiceAgreementRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/UserServiceAgreementVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/UserServiceAgreementTh'
				})
			}
    },
  },
};
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}

h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}

li {
	font-size: 0.3rem;
}
</style>